import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShareSquare, faCommentAlt, faCog, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { logoutUser } from '../actions/auth';

import logo from '../assets/logo.png';

const JWTKey = 'MEETUPAPPJWT';

class Header extends Component {
    logoutUser = () => {
        this.props.logoutUser();
    }

    render() {
        return (
            <div className="sb-header">
                <div className="row align-items-center">
                    <div className="col-6">
                        <Link to='/'><img src={logo} alt="Logo" className="logo" /></Link>
                        {/* TODO: Only show premium text if user has premium account */}
                        {/* <p className="logoPremium">premium</p> */}
                    </div>
                    <div className="col-6">
                        <div className="divHeaderButtons">
                            <a className="headerIcon" href="mailto:?subject=stumbls%20-%20meine%20Empfehlung&body=Hallo%2C%0A%0Akennst%20du%20schon%20stumbls.de%3F%20Trage%20deine%20Gesch%C3%A4ftsreisen%20ein%20und%20triff%20Kollegen%2C%20die%20in%20deiner%20Stadt%20unterwegs%20sind.%20Registrier%20dich%20und%20probier%20es%20gleich%20aus.%0A%0ABeste%20Gr%C3%BC%C3%9Fe">
                                <FontAwesomeIcon icon={faShareSquare} title="Weiterempfehlen" />
                            </a>
                            
                            <a className="headerIcon" href="mailto:feedback@stumbls.de"><FontAwesomeIcon icon={faCommentAlt} title="Feedback" /></a>
                            
                            { 
                                this.props.authentication.loggedIn ? 
                                <Link className="headerIcon" to='/user'><FontAwesomeIcon icon={faCog} /></Link> :
                                null
                            }
                        </div>
                    </div>
                </div>
                
                <hr />

                {this.props.children}
            </div>
        )
    }
}

export default connect(
    ({ authentication }) => ({ authentication }),
    { logoutUser }
)(Header);