export const AUTH = {
  REGISTER: "REGISTER",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_ERROR: "REGISTER_ERROR",

  LOGIN: "LOGIN",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",

  LOGOUT: "LOGOUT",

  SET_TOKEN: "SET_TOKEN",
  SET_TOKEN_LOADING: "SET_TOKEN_LOADING",
  SET_TOKEN_NOT_LOADING: "SET_TOKEN_NOT_LOADING",

  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_ERROR: "FORGOT_PASSWORD_ERROR",

  SET_PASSWORD: "SET_PASSWORD",
  SET_PASSWORD_SUCCESS: "SET_PASSWORD_SUCCESS",
  SET_PASSWORD_ERROR: "SET_PASSWORD_ERROR"
};

export const USER = {
  GET_USERDATA: "GET_USERDATA",
  GET_USERDATA_SUCCESS: "GET_USERDATA_SUCCESS",
  GET_USERDATA_ERROR: "GET_USERDATA_ERROR",

  UPDATE_FIRSTNAME: "UPDATE_FIRSTNAME",
  UPDATE_LASTNAME: "UPDATE_LASENAME",
  UPDATE_NOTIFICATION_DAILY: "UPDATE_NOTIFICATION_DAILY",
  UPDATE_NOTIFICATION_IMMEDIATELY: "UPDATE_NOTIFICATION_IMMEDIATELY",
  UPDATE_USERSETTINGS: "POST_UPDATE_USERSETTINGS",
  UPDATE_USERSETTINGS_SUCCESS: "POST_UPDATE_USERSETTINGS_SUCCESS",
  UPDATE_USERSETTINGS_ERROR: "POST_UPDATE_USERSETTINGS_ERROR"
};

export const TRIP = {
  ADD_TRIPS: "ADD_TRIPS",

  CREATE_TRIP: "CREATE_TRIP",
  CREATE_TRIP_SUCCESS: "CREATE_TRIP_SUCCESS",
  CREATE_TRIP_ERROR: "CREATE_TRIP_ERROR",

  UPDATE_TRIP: "UPDATE_TRIP",
  UPDATE_TRIP_SUCCESS: "UPDATE_TRIP_SUCCESS",
  UPDATE_TRIP_ERROR: "UPDATE_TRIP_ERROR",

  DELETE_TRIP: "DELETE_TRIP",
  DELETE_TRIP_SUCCESS: "DELETE_TRIP_SUCCESS",
  DELETE_TRIP_ERROR: "DELETE_TRIP_ERROR",

  GET_TRIPS: "GET_TRIPS",
  GET_TRIPS_SUCCESS: "GET_TRIPS_SUCCESS",
  GET_TRIPS_ERROR: "GET_TRIPS_ERROR",

  SAVE_TRIP: "SAVE_TRIP",
  SAVE_TRIPS: "SAVE_TRIPS",

  UPDATE_SELECTED_CITY: "UPDATE_SELECTED_CITY"
};

export const CITY = {
  GET_ALL_CITIES: "GET_ALL_CITIES",
  GET_ALL_CITIES_SUCCESS: "GET_ALL_CITIES_SUCCESS",
  GET_ALL_CITIES_ERROR: "GET_ALL_CITIES_ERROR"
};

export const ADVERTISEMENT = {
  GET_ADVERTISEMENT_FOR_TRIP_ID: "GET_ADVERTISEMENT_FOR_TRIP_ID",
  GET_ADVERTISEMENT_FOR_TRIP_ID_SUCCESS:
    "GET_ADVERTISEMENT_FOR_TRIP_ID_SUCCESS",
  GET_ADVERTISEMENT_FOR_TRIP_ID_ERROR: "GET_ADVERTISEMENT_FOR_TRIP_ID_ERROR",

  CLICK_ADVERTISEMENT: "CLICK_ADVERTISEMENT",
  CLICK_ADVERTISEMENT_SUCCESS: "CLICK_ADVERTISEMENT_SUCCESS",
  CLICK_ADVERTISEMENT_ERROR: "CLICK_ADVERTISEMENT_ERROR",

  DELETE_ADVERTISEMENT_FOR_TRIP: "DELETE_ADVERTISEMENT_FOR_TRIP"
};
