import React, { Component } from 'react';
import Moment from 'react-moment';
import 'moment/locale/de';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faUsers, faChevronDown, faChevronUp, faComments, faArrowRight} from '@fortawesome/free-solid-svg-icons'
import { Row, Col } from 'react-bootstrap';

class Attendees extends Component {
    doMail = () => { 
        const { attendees, tripdate, city } = this.props;

        var attendeeMails = attendees.map((attendee, index) => {
            return ( 
                index != attendees.length-1 ? (attendee.email + ',%20') : attendee.email );
        });

        var mailSubject = "Wir%20haben%20einen%20stumble%20am%20";
        mailSubject += moment.unix(tripdate).format("DD.MM.YYYY").toString();
        mailSubject += "%20in%20";
        mailSubject += city;
        mailSubject += "!";

        location.href = "mailto:" + attendeeMails +"?subject="+mailSubject;
    }

    render() {
        const { attendees, tripdate, city } = this.props;

        var attendeeMails = attendees.map((attendee, index) => {
            return ( 
                index != attendees.length-1 ? (attendee.email + ',%20') : attendee.email );
        });

        var mailSubject = "Wir%20haben%20einen%20stumble%20am%20";
        mailSubject += moment.unix(tripdate).format("DD.MM.YYYY").toString();
        mailSubject += "%20in%20";
        mailSubject += city;
        mailSubject += "!";

        return (
            <Row>
                <Col>
                    <div className="divAttendeeCard" onClick={this.doMail}>
                        <div className="divTitleAttendee">
                            <div className="faAttendeesTitle">Deine stumbls</div>
                            <FontAwesomeIcon icon={faUsers} className="faAttendeesIcon" />
                        </div>
                        { 
                            attendees.map((attendee, index) => {
                                const { email, firstname, lastname } = attendee;

                                return (
                                    <div key={index}>
                                        <div className="attendeesList" title={email} >
                                            <div className="faAttendeesText">
                                                { firstname && lastname ? ( firstname + " " + lastname ) : email }
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) 
                        }
                        <div className="divAttendeeButton">
                            <button className="btn btn-light">
                                <FontAwesomeIcon icon={faComments} className="fa-lg" 
                                    style={{ color: '#51b7af' }} />                        
                            </button>
                        </div>
                    </div>
                </Col>
            </Row>
        )
    }
}

export default Attendees;