import React, { Component } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Button, Spinner, Row, Col } from 'react-bootstrap';
import { registerAction } from '../actions/auth';
import RegisterForm from './Forms/RegisterForm';

class Register extends Component {
    state = { 
        email: '', 
        validEmail: false, 
        emptyEmail: true, 
        password: '', 
        passwordConfirm: '',
        passwordValid: false,
        passwordMatching: true
    };

    componentDidMount() {
        if(!this.props.location) return;
        if(!this.props.location.state) return;

        if(this.props.location.state.email) {
            this.setState({ email: this.props.location.state.email }, () => {
                this.validateEmail();
            });
        }
    }

    updateForm = event => {
        const name = event.target.name;
        this.setState({ [name]: event.target.value }, () => {
            if(name === "email") {   
                this.validateEmail();
            }
            if(name === "password" || name === "passwordConfirm") {   
                this.checkPasswordValid();
                this.checkPasswordMatching();
            }
        });
    }

    validateEmail() {
        if(this.state.email.length === 0)
            this.setState({ emptyEmail: true });
        else
            this.setState({ emptyEmail: false });

        this.setState({ validEmail: true });
    }

    checkPasswordValid() {
        const { password } = this.state;

        if(password.length < 5) {
            this.setState({ passwordValid: false});
        } else {
            this.setState({ passwordValid: true });
        }
    }

    checkPasswordMatching() {
        const { password, passwordConfirm } = this.state;

        if(password === passwordConfirm) {
            this.setState({ passwordMatching: true })
        } else {
            this.setState({ passwordMatching: false })
        }
    }

    sendForm = event => {
        event.preventDefault();
        if(!this.state.validEmail || this.state.emptyEmail || !this.state.passwordMatching) {
            return null;
        }
        this.props.registerAction(this.state.email, this.state.password);
    }

    render() {
        const { validEmail, emptyEmail, passwordValid, passwordMatching, email } = this.state;
        const { authentication } = this.props;
        const { registerLoading, registerSuccessful, registerError } = authentication;


        if(authentication.loggedIn) {
            return (<Redirect to="/" />)
        }

// TODO: ADD FEEDBACK: See: Formik

        return(
            <div align="center">
                <Row className="justify-content-center">
                    <Col className="text-center">
                        <p className="stumblsHeader">Register</p>
                    </Col>
                </Row>

                {/* <RegisterForm /> */}

                { registerSuccessful ? 
                <div className="registerSuccess">
                    <p>Registrierung erfolgreich. Bitte Account aktivieren!</p>
                </div> : null }

                <Form className="text-center">
                    <Form.Row className="justify-content-center m5">
                        <Form.Control 
                            className="text-center input-max-400"
                            name="email"
                            type="email"
                            placeholder="E-Mail"
                            onChange={this.updateForm} 
                            onKeyPress={this.handleKeyPress}
                            disabled={registerLoading}
                            value={email}
                            required
                        />
                        <Form.Control.Feedback type="invalid">
                            Bitte E-Mail eingeben.
                        </Form.Control.Feedback>
                    </Form.Row>
                    <Form.Row className="justify-content-center m5">
                        <Form.Control 
                            className="text-center input-max-400"
                            name="password"
                            type="password" 
                            placeholder="Passwort"
                            onChange={this.updateForm} 
                            onKeyPress={this.handleKeyPress}
                            disabled={registerLoading}
                        />
                    </Form.Row>
                    <Form.Row className="justify-content-center m5">
                        <Form.Control 
                            className="text-center input-max-400"
                            name="passwordConfirm"
                            type="password" 
                            placeholder="Passwort"
                            onChange={this.updateForm} 
                            onKeyPress={this.handleKeyPress}
                            disabled={registerLoading}
                        />
                    </Form.Row>
                    <Form.Row className="justify-content-center m5">
                        <Button 
                            variant="secondary"
                            className="btn-200"
                            type="submit"
                            onClick={this.sendForm}
                            disabled={!validEmail || emptyEmail || registerLoading || !passwordValid || !passwordMatching}
                        >
                            { registerLoading ? <Spinner as="span" animation="border" role="status" aria-hidden="true" size="sm" className="spinner-button" /> : null }
                            REGISTRIEREN
                        </Button>
                    </Form.Row>
                </Form>
                
                <p style={{ marginTop: 10 }}>
                    <Link to={{ pathname:"/login", state: { email: this.state.email } }}>Bereits registriert?</Link>
                </p>
            </div>
        )
    }
}

export default connect(
    ({ authentication }) => ({ authentication }), 
    { registerAction }
)(withRouter(Register));