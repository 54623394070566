import { TRIP } from '../actions/types';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/de';
import { uuid } from 'uuidv4';

const DEFAULT_TRIP = { };

const tripReducer  = (state = DEFAULT_TRIP, action) => {
    switch(action.type) { 
        case TRIP.GET_TRIPS:
            return { ...state, loading: true, error: null };

        case TRIP.GET_TRIPS_SUCCESS:
            return { ...state, loading: false, error: null, trips: action.trips };

        case TRIP.GET_TRIPS_ERROR:
            return { ...state, loading: false, error: action.message };

        case TRIP.ADD_TRIPS:
            // TODO: This function is not PURE. Find a way to create a PURE function.

            // Find lastTripDate
            var trips = [...state.trips];

            // Find weekdays of trips
            // SO: 0, MO: 1
            var weekdays = [];
            if(trips) {
                for(var i = 0; i < trips.length; i++) {
                    weekdays.push(moment(trips[i].date*1000).day());
                }
            }

            // Add trips for next seven days except days already received from trip
            for(var i = 0; i < 7; i++) {
                var day = moment().startOf('day').add(i, 'days');

                if(!weekdays.includes(day.day())) {
                    var date = day;
                    const trip = { date: (date.unix()), city_id: 0 };
                    trips = [ ...trips, trip];
                }
            }

            // Add a uuid to every trip
            trips.forEach(trip => {
                if(!trip.uuid) {
                    trip.uuid = uuid();
                }
            })

            // Order Trips by date
            var trips = trips.sort((a,b) => {
                return new Date(a.date*1000) - new Date(b.date*1000);
            })

            return { ...state, trips: trips };

        case TRIP.SAVE_TRIP:
            var newTrips = [...state.trips];

            newTrips = newTrips.map((trip) => {
                if (trip.uuid === action.uuid) {
                    return Object.assign({}, trip, {
                        loading: true,
                        updated: false,
                        error: null
                    })
                }
                return trip;
            });

            return { ...state, trips: newTrips };

        case TRIP.CREATE_TRIP:
            var newTrips = [...state.trips];

            newTrips = newTrips.map((trip) => {
                if (trip.uuid === action.uuid) {
                    return Object.assign({}, trip, {
                        loading: false,
                        updated: false,
                        error: null
                    })
                }
                return trip;
            });

            return { ...state, trips: newTrips };

        case TRIP.CREATE_TRIP_SUCCESS:
            var newTrips = [...state.trips];

            newTrips = newTrips.map((trip) => {
                if (trip.uuid === action.uuid) {
                    return Object.assign({}, trip, {
                        id: action.trip.id,
                        loading: false,
                        changed: false,
                        updated: true,
                        error: null,
                        attendees: action.trip.attendees,
                    })
                }
                return trip;
            });

            return { ...state, trips: newTrips };

        case TRIP.CREATE_TRIP_ERROR:
            var newTrips = [...state.trips];

            newTrips = newTrips.map((trip) => {
                if (trip.uuid === action.uuid) {
                    return Object.assign({}, trip, {
                        id: action.tripId,
                        loading: false,
                        changed: false,
                        error: action.message
                    })
                }
                return trip;
            });

        case TRIP.UPDATE_TRIP:
            var newTrips = [...state.trips];

            newTrips = newTrips.map((trip) => {
                if (trip.uuid === action.uuid) {
                    return Object.assign({}, trip, {
                        loading: true,
                        updated: false,
                        error: null
                    })
                }
                return trip;
            });

            return { ...state, trips: newTrips };

        case TRIP.UPDATE_TRIP_SUCCESS:
            var newTrips = [...state.trips];

            newTrips = newTrips.map((trip) => {
                if (trip.uuid === action.uuid) {
                    return Object.assign({}, trip, {
                        loading: false,
                        changed: false,
                        updated: true,
                        error: null,
                        attendees: action.trip.attendees,
                    })
                }
                return trip;
            });
            
            return { ...state, trips: newTrips };

        case TRIP.UPDATE_TRIP_ERROR:
            var newTrips = [...state.trips];

            newTrips = newTrips.map((trip) => {
                if (trip.uuid === action.uuid) {
                    return Object.assign({}, trip, {
                        loading: false,
                        changed: false, // TODO: Maybe changed: true to be able to send again?
                        error: action.message,
                    })
                }
                return trip;
            });
            
            return { ...state, trips: newTrips };

        case TRIP.DELETE_TRIP:
            var newTrips = [...state.trips];

            newTrips = newTrips.map((trip) => {
                if (trip.uuid === action.uuid) {
                    return Object.assign({}, trip, {
                        id: null,
                        city_id: 0,
                        loading: true,
                        updated: false,
                        error: null,
                    })
                }
                return trip;
            });
            
            return { ...state, trips: newTrips };

        case TRIP.DELETE_TRIP_SUCCESS:
            var newTrips = [...state.trips];

            newTrips = newTrips.map((trip) => {
                if (trip.uuid === action.uuid) {
                    return Object.assign({}, trip, {
                        loading: false,
                        changed: false,
                        updated: true,
                        attendees: null,
                        error: null,
                    })
                }
                return trip;
            });
            
            return { ...state, trips: newTrips };

        case TRIP.DELETE_TRIP_ERROR:
            var newTrips = [...state.trips];

            newTrips = newTrips.map((trip) => {
                if (trip.uuid === action.uuid) {
                    return Object.assign({}, trip, {
                        loading: false,
                        changed: false, // TODO: Maybe true, to be able to send form again
                        error: action.message,
                    })
                }
                return trip;
            });
            
            return { ...state, trips: newTrips };

        case TRIP.UPDATE_SELECTED_CITY:
            var newTrips = [...state.trips];

            newTrips = newTrips.map((trip) => {
                let changed = trip.city_id != action.value;

                if (trip.uuid === action.uuid) {
                    return Object.assign({}, trip, {
                        city_id: action.value,
                        updated: false,
                        changed: changed,
                        error: null,
                    })
                }
                return trip;
            });
            
            return { ...state, trips: newTrips };

        default:
            return state;
    }
};

export default tripReducer;