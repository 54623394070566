import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { setTokenAction, setNoTokenAction, setTokenLoadingAction } from '../actions/auth';
import TripBoard from './TripBoard';
import LandingPage from './LandingPage';

const JWTKey = 'MEETUPAPPJWT';

class App extends Component {
    componentDidMount() {
        this.props.setTokenLoadingAction();
        let token = localStorage.getItem(JWTKey);
        if(token) {
            this.props.setTokenAction(token);
            // TODO Check if token is still valid
        } else {
            this.props.setNoTokenAction();
        }
    }

    render() {
        const { authentication } = this.props;

        if(!authentication.tokenLoaded) {
            return ( 
                <div align="center" className="divSpace">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Authentifizierung wird geprüft...</span>
                    </Spinner>
                    <p>Authentifizierung wird geprüft...</p>
                </div>
            )
        }

        if(!authentication.loggedIn) {
            return ( <div><LandingPage /></div> )
        }

        return ( <div><TripBoard /></div> )
    }
}

export default connect(
    ({ authentication }) => ({ authentication }),
    { setTokenAction, setNoTokenAction, setTokenLoadingAction }
)(App);
