import React, { Component } from "react";
import { connect } from "react-redux";
import { Spinner, Row, Col } from "react-bootstrap";
import {
  getAdvertisementForTripId,
  clickAdvertisementAction
} from "../actions/advertisement";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

/* TODO: FIX THAT!
Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in the componentWillUnmount method.
    in Carousel (created by Advertisement)
    in div (created by Advertisement)
    in Advertisement (created by ConnectFunction)
    in ConnectFunction (created by TripBoard)
    in div (created by TripBoard)
*/

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 4 // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 3 // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    slidesToSlide: 2 // optional, default to 1.
  }
};

class Advertisement extends Component {
  componentDidMount() {
    const { trip } = this.props;

    if (!trip.uuid) return;
    if (!trip.id) return; // No Trip
    if (trip.city_id == 0) return; // No Trip

    this.props.getAdvertisementForTripId(trip.uuid, trip.id);
  }

  clickAdvertisementAction = (redirect_url, event_url) => () => {
    this.props.clickAdvertisementAction(event_url);
    const timer = setTimeout(() => {
      window.open(redirect_url, "_blank");
    }, 300);
  };

  render() {
    const { trip, advertisements } = this.props;

    if (!advertisements) return null;
    if (advertisements.loading) {
      return (
        <div>
          <Spinner
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
            size="sm"
            className="spinner-button"
          />
          Lade...
        </div>
      );
    }
    if (!advertisements.advertisements) return null;

    const ads = advertisements.advertisements;

    return (
      <div className="tripAdvertisement">
        {ads[trip.uuid] ? (
          <Carousel responsive={responsive} swipeable={true} draggable={false}>
            {/* <Row noGutters> */}
            {ads[trip.uuid].map((ad, index) => {
              return (
                <div
                  key={index}
                  className="d-flex divAdvertisementCard"
                  onClick={this.clickAdvertisementAction(
                    ad.redirect_url,
                    ad.event_url
                  )}
                  style={{ backgroundImage: `url(${ad.image_url})` }}
                >
                  <div className="textAdvertisementCard backgroundHighlight">
                    {ad.text}
                  </div>
                </div>
              );
            })}
            {/* </Row> */}
          </Carousel>
        ) : null}
      </div>
    );
  }
}

export default connect(({ advertisements }) => ({ advertisements }), {
  getAdvertisementForTripId,
  clickAdvertisementAction
})(Advertisement);
