import React from 'react'
import { Form, Button, Spinner, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form'
import { registerAction } from '../../actions/auth';

export default function RegisterForm() {
  const { register, handleSubmit, watch, errors } = useForm()

//   const onSubmit = (data) => {
//       props.registerAction(data.email, data.password);
//   }
  const onSubmit = data => { console.log(data) }

  return (
      <div align="center">
        <Form className="text-center" onSubmit={handleSubmit(onSubmit)}>
            <Form.Row className="justify-content-center m5">
                <Form.Control 
                    className="text-center input-max-400"
                    name="email"
                    type="email"
                    placeholder="E-Mail"
                    ref={register({ required: true })}
                />
                {errors.email && 'E-Mail wird benötigt.'}
            </Form.Row>
            <Form.Row className="justify-content-center m5">
                <Form.Control 
                    className="text-center input-max-400"
                    name="password"
                    type="password" 
                    placeholder="Passwort"
                    ref={register({ required: true })}
                />
                {errors.password && 'Passwort wird benötigt.'}
            </Form.Row>
            <Form.Row className="justify-content-center m5">
                <Form.Control 
                    className="text-center input-max-400"
                    name="passwordConfirm"
                    type="password" 
                    placeholder="Passwort"
                    ref={register({ required: true })}
                />
                {errors.passwordConfirm && 'Passwort wird benötigt.'}
            </Form.Row>
            <Form.Row className="justify-content-center m5">
                <Button 
                    variant="secondary"
                    className="btn-200"
                    type="submit">
                    REGISTRIEREN
                </Button>
            </Form.Row>
        </Form>
    </div>
  )
}

// Connect your component with redux
connect(
    ({ authentication }) => ({ authentication }), 
    { registerAction }
)(RegisterForm)