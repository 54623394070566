import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form, Button, Spinner, Row, Col } from 'react-bootstrap';
import { forgotPasswordAction } from '../actions/auth';

class ForgotPassword extends Component {
    state = { email: '', loading: false };

    componentDidMount() {
        if(!this.props.location || !this.props.location.state) return;
        if(this.props.location.state.email) {
            this.setState({ email: this.props.location.state.email });
        }
    }

    updateForm = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    sendForm = () => {
        event.preventDefault();

        const { email } = this.state;
        this.props.forgotPasswordAction(email);
    }

    handleKeyPress = event => {
        if(event.Key === 'Enter') {
            this.sendForm();
        } 
    }

    render() {
        const { email } = this.state;
        const { loading, success } = this.props.authentication;

        return(
            <div align="center">
                <Row className="justify-content-center">
                    <Col className="text-center">
                        <p className="stumblsHeader">Passwort vergessen</p>
                    </Col>
                </Row>
                <Form>
                    <Form.Row className="justify-content-center m5">
                        <Form.Control 
                            className="text-center input-max-400"
                            name="email"
                            type="email" 
                            placeholder="E-Mail"
                            onChange={this.updateForm} 
                            onKeyPress={this.handleKeyPress}
                            disabled={loading}
                            value={email}
                        />
                    </Form.Row>
                    
                    <Form.Row className="justify-content-center m5">
                        <Button 
                            variant="secondary"
                            className="btn-200"
                            type="submit"
                            onClick={this.sendForm}
                            disabled={loading || email.length == 0}
                        >
                            { loading ? <Spinner as="span" animation="border" role="status" aria-hidden="true" size="sm" className="spinner-button" /> : null }
                            SENDEN
                        </Button>
                    </Form.Row>

                    <p className="registerSuccess">
                        { success ? "Eine E-Mail zum Zurücksetzen des Passworts wurde versendet." : null }
                    </p>

                    <p className="registerError">
                        { this.props.authentication.error }
                    </p>
                </Form>
            </div>
        )
    }
}

export default connect(
    ({ authentication }) => ({ authentication }),
    { forgotPasswordAction }
)(withRouter(ForgotPassword));