import { AUTH } from '../actions/types';

const DEFAULT_ERROR = { error: false, message: '' }

const errorReducer  = (state = DEFAULT_ERROR, action) => {
    switch(action.type) {
        case AUTH.USER_REGISTER_ERROR:
            return { ...state, error: true, message: action.message };

        case AUTH.USER_LOGIN_ERROR:
            return { ...state, error: true, message: action.message };
            
        default:
            // TODO
            // return state;
            return DEFAULT_ERROR;
    }
}

export default errorReducer;