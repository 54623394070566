import React, { Component } from "react";
import Trip from "./Trip";
import Advertisement from "./Advertisement";
import Attendees from "./Attendees";
// import Trip from './TripNew';
import { connect } from "react-redux";
import { Spinner } from "react-bootstrap";
import { getAllCities } from "../actions/city";
import { getAdvertisementsAction } from "../actions/advertisement";

class TripBoard extends Component {
  componentDidMount() {
    // Gets the list of cities for the dropdowns
    // When loading is finished the list of trips will be requested after
    this.props.getAllCities();
  }

  componentDidUpdate() {
    // If the trips updated get all advertisements
    if (this.props.trips.trips) {
      this.props.getAdvertisementsAction(this.props.trips.trips);
    }
  }

  getCityNameFromId = city_id => {
    const { cities } = this.props;
    if (!cities.cities) return "Unbekannt";
    let city = cities.cities.find(element => element.id === String(city_id));
    if (city) return city.name;
    return "Keine Reise";
  };

  render() {
    const { cities, trips } = this.props;

    if (!cities.cities || cities.loading) {
      return (
        <div>
          <Spinner
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
            size="sm"
            className="spinner-button"
          />
          Ziele werden geladen...
        </div>
      );
    }
    if (cities.error) {
      return <div>{cities.error}</div>;
    }

    if (!trips.trips || trips.loading) {
      return (
        <div>
          <Spinner
            as="span"
            animation="border"
            role="status"
            aria-hidden="true"
            size="sm"
            className="spinner-button"
          />
          Reisen werden geladen...
        </div>
      );
    }
    if (trips.error) {
      return <div>{trips.error}</div>;
    }

    return (
      <div>
        {trips.trips.map((trip, index) => {
          return (
            <div key={index} className="divTripBoard">
              <Trip trip={trip} />
              {trip.attendees && trip.attendees.length != 0 ? (
                <div>
                  <Attendees
                    attendees={trip.attendees}
                    tripdate={trip.date}
                    city={this.getCityNameFromId(trip.city_id)}
                  />
                </div>
              ) : null}
              {/* Show ads for trip if available */}
              {trip.uuid ? <Advertisement trip={trip} /> : null}
              {/* <hr style={{ width: '100px', marginBottom: '25px', }} /> */}
            </div>
          );
        })}
      </div>
    );
  }
}

export default connect(({ cities, trips }) => ({ cities, trips }), {
  getAllCities,
  getAdvertisementsAction
})(TripBoard);
