import { AUTH } from "./types";

const JWTKey = "MEETUPAPPJWT";
const API_URL = "https://www.stumbls.de/api";

// ###################
// REGISTER
// ###################
export const registerAction = (email, password) => dispatch => {
  dispatch(register());

  var formData = new FormData();
  formData.append("email", email);
  formData.append("password", password);

  return fetch(`${API_URL}/user`, {
    method: "POST",
    headers: {
      Accept: "application/json"
    },
    body: formData
  })
    .then(response => {
      if (response.status !== 200) {
        throw new Error("Registrierung fehlgeschlagen");
      }

      return response.json();
    })
    .then(json => {
      if (!json.success) {
        throw new Error("Registrierung fehlgeschlagen");
      }
      dispatch(registerSuccess(json));

      if (json.data.activated === 1) {
        dispatch(loginUser(email, password));
      }
    })
    .catch(error => dispatch(registerError(error)));
};
export const register = () => {
  return { type: AUTH.REGISTER };
};
export const registerSuccess = registerJson => {
  const { userId } = registerJson.data;
  return { type: AUTH.REGISTER_SUCCESS, payload: { userId } };
};
export const registerError = error => {
  return { type: AUTH.REGISTER_ERROR, message: error.message };
};

// ###################
// TOKEN (Loading)
// ###################
export const setTokenLoadingAction = () => dispatch => {
  dispatch(setTokenLoading());
};
export const setTokenAction = token => dispatch => {
  dispatch(setToken(token));
};
export const setNoTokenAction = () => dispatch => {
  dispatch(setTokenNotLoading());
};
export const setTokenLoading = () => {
  return { type: AUTH.SET_TOKEN_LOADING };
};
export const setTokenNotLoading = () => {
  return { type: AUTH.SET_TOKEN_NOT_LOADING };
};
export const setToken = token => {
  return { type: AUTH.SET_TOKEN, token };
};

// ###################
// LOGIN
// ###################
export const loginUser = (email, password) => dispatch => {
  dispatch(postLogin());

  var formData = new FormData();
  formData.append("email", email);
  formData.append("password", password);

  return fetch(`${API_URL}/user/login`, {
    method: "POST",
    headers: {
      Accept: "application/json"
    },
    body: formData
  })
    .then(response => {
      if (response.status !== 200) {
        throw new Error("Es ist ein Fehler aufgetreten.");
      }

      return response.json();
    })
    .then(json => {
      if (!json.success) {
        throw new Error("Benutzername oder Passwort falsch.");
      }
      dispatch(postLoginSuccess(json));
    })
    .catch(error => dispatch(postLoginError(error)));
};
export const postLogin = () => {
  return { type: AUTH.LOGIN };
};
export const postLoginSuccess = loginJson => {
  const token = loginJson.data;
  localStorage.setItem(JWTKey, token);
  return { type: AUTH.LOGIN_SUCCESS, token };
};
export const postLoginError = error => {
  return { type: AUTH.LOGIN_ERROR, message: error.message };
};

// ###################
// LOGOUT
// ###################
export const logoutUser = () => dispatch => {
  dispatch(postLogout());
};
export const postLogout = () => {
  localStorage.removeItem(JWTKey);
  return { type: AUTH.LOGOUT };
};

// ###################
// FORGOT PASSWORD
// ###################
export const forgotPasswordAction = email => dispatch => {
  dispatch(forgotPassword());

  var formData = new FormData();
  formData.append("email", email);

  return fetch(`${API_URL}/user/password`, {
    method: "POST",
    headers: {
      Accept: "application/json"
    },
    body: formData
  })
    .then(response => {
      if (response.status !== 200) {
        throw new Error("Senden fehlgeschlagen");
      }
      dispatch(forgotPasswordSuccess());
    })
    .catch(error => dispatch(forgotPasswordError(error)));
};
export const forgotPassword = () => {
  return { type: AUTH.FORGOT_PASSWORD };
};
export const forgotPasswordSuccess = () => {
  return { type: AUTH.FORGOT_PASSWORD_SUCCESS };
};
export const forgotPasswordError = error => {
  return { type: AUTH.FORGOT_PASSWORD_ERROR, message: error.message };
};

// ###################
// FORGOT PASSWORD
// ###################
export const setPasswordAction = (password, token) => dispatch => {
  dispatch(setPassword());

  var formData = new FormData();
  formData.append("token", token);
  formData.append("password", password);

  return fetch(`${API_URL}/user/password`, {
    method: "POST",
    headers: {
      Accept: "application/json"
    },
    body: formData
  })
    .then(response => {
      if (response.status !== 200) {
        throw new Error("Senden fehlgeschlagen");
      }

      return response.json();
    })
    .then(json => {
      if (!json.success) {
        throw new Error("Senden fehlgeschlagen");
      }
      dispatch(setPasswordSuccess(json));
    })
    .catch(error => dispatch(setPasswordError(error)));
};
export const setPassword = () => {
  return { type: AUTH.SET_PASSWORD };
};
export const setPasswordSuccess = json => {
  return { type: AUTH.SET_PASSWORD_SUCCESS, payload: json.data };
};
export const setPasswordError = error => {
  return { type: AUTH.SET_PASSWORD_ERROR, message: error.message };
};
