import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form, Button, Spinner } from 'react-bootstrap';
import { setPasswordAction } from '../actions/auth';

class SetPassword extends Component {
    state = { password: '', passwordConfirm: '', passwordMatching: true };
    
    componentDidMount() {
        this.setState({ token: this.props.match.params.token})
    }

    updateForm = event => {
        this.setState({ [event.target.name]: event.target.value }, () => {
            this.checkPasswordMatching();
        });
    }

    checkPasswordMatching() {
        const { password, passwordConfirm } = this.state;

        if(password === passwordConfirm) {
            this.setState({ passwordMatching: true })
        } else {
            this.setState({ passwordMatching: false })
        }
    }

    sendForm = () => {
        event.preventDefault();

        const { password, token } = this.state;
        this.props.setPasswordAction(password, token);
    }

    handleKeyPress = event => {
        if(event.Key === 'Enter') {
            this.sendForm();
        } 
    }

    render() {
        const { password, passwordConfirm, passwordMatching } = this.state;
        const { loading } = this.props.authentication;

        return(
            <div>
                <Form>
                    <Form.Row className="justify-content-center m5">
                        <Form.Control 
                            className="text-center input-max-400"
                            name="password"
                            type="password" 
                            placeholder="Passwort"
                            onChange={this.updateForm} 
                            onKeyPress={this.handleKeyPress}
                            disabled={loading}
                        />
                    </Form.Row>
                    <Form.Row className="justify-content-center m5">
                        <Form.Control 
                            className="text-center input-max-400"
                            name="passwordConfirm"
                            type="password" 
                            placeholder="Passwort verifizieren"
                            onChange={this.updateForm} 
                            onKeyPress={this.handleKeyPress}
                            disabled={loading}
                        />
                    </Form.Row>
                    <Form.Row className="justify-content-center m5">
                        <Button 
                            variant="secondary"
                            className="btn-200"
                            type="submit"
                            onClick={this.sendForm}
                            disabled={loading || password.length == 0 || !passwordMatching}
                        >
                            { loading ? <Spinner as="span" animation="border" role="status" aria-hidden="true" size="sm" className="spinner-button" /> : null }
                            SPEICHERN
                        </Button>
                    </Form.Row>
                </Form>
            </div>
        )
    }
}

export default connect(
    ({ authentication }) => ({ authentication }),
    { setPasswordAction }
)(withRouter(SetPassword));

{/* <input 
                        style={{ marginTop: 10 }}
                        onChange={this.updatePasswordConfirm} 
                        onKeyPress={this.handleKeyPress}
                        id="passwordConfirm"
                        type="password" 
                        placeholder="Passwort bestätigen" 
                        className={"form-control " + (this.state.passwordMatching ? 'is-valid' : 'is-invalid') }
                    />
                    <div className="invalid-feedback">
                        Die Passwörter stimmen nicht überein.
                    </div> */}