import { ADVERTISEMENT } from "./types";
import { authHeader } from "../helpers/auth";

const API_URL = "https://www.stumbls.de/api";

// ###################
// GET ADVERTISEMENT FOR TRIP ID
// ###################
export const getAdvertisementForTripId = (tripUUID, tripId) => dispatch => {
  dispatch(postGetAdvertisementForTripId());

  return fetch(`${API_URL}/ad?trip_id=${tripId}`, {
    method: "GET",
    headers: authHeader()
  })
    .then(response => {
      if (response.status !== 200) {
        throw new Error("Anfrage zu Endpunkt fehlgeschlagen.");
      }
      return response.json();
    })
    .then(json => {
      if (!json.success) {
        throw new Error("Antwort von Endpunkt fehlerhaft.");
      }
      dispatch(postGetAdvertisementForTripIdSuccess(tripUUID, json));
    })
    .catch(error => dispatch(postGetAdvertisementForTripIdError(error)));
};
export const postGetAdvertisementForTripId = () => {
  return { type: ADVERTISEMENT.GET_ADVERTISEMENT_FOR_TRIP_ID };
};
export const postGetAdvertisementForTripIdSuccess = (
  tripUUID,
  advertisementJson
) => {
  return {
    type: ADVERTISEMENT.GET_ADVERTISEMENT_FOR_TRIP_ID_SUCCESS,
    uuid: tripUUID,
    advertisements: advertisementJson.data
  };
};
export const postGetAdvertisementForTripIdError = error => {
  return {
    type: ADVERTISEMENT.GET_ADVERTISEMENT_FOR_TRIP_ID_ERROR,
    message: error.message
  };
};

export const getAdvertisementsAction = trips => dispatch => {
  for (var i = 0; i < trips.length; i++) {
    const trip = trips[i];
    var tripId = Number(trip.id);
    var { uuid } = trip;

    if (tripId && tripId != NaN && tripId != 0) {
      dispatch(getAdvertisementForTripId(uuid, tripId));
    } else {
      dispatch(deleteAdvertisementForTrip(uuid));
    }
  }
};

export const deleteAdvertisementForTrip = tripUUID => {
  return { type: ADVERTISEMENT.DELETE_ADVERTISEMENT_FOR_TRIP, uuid: tripUUID };
};

export const clickAdvertisementAction = url => dispatch => {
  dispatch(clickAdvertisement());

  return fetch(`${url}`, {
    method: "GET",
    headers: authHeader()
  })
    .then(response => {
      if (response.status !== 200) {
        throw new Error("Anfrage zu Endpunkt fehlgeschlagen.");
      }
      dispatch(clickAdvertisementSuccess());
    })
    .catch(error => dispatch(clickAdvertisementError(error)));
};
export const clickAdvertisement = () => {
  return { type: ADVERTISEMENT.CLICK_ADVERTISEMENT };
};
export const clickAdvertisementSuccess = () => {
  return { type: ADVERTISEMENT.CLICK_ADVERTISEMENT_SUCCESS };
};
export const clickAdvertisementError = error => {
  return {
    type: ADVERTISEMENT.CLICK_ADVERTISEMENT_ERROR,
    message: error.message
  };
};
