import { USER } from '../actions/types';

const DEFAULT_USER = { loading: true, error: null, user: null };

const userReducer  = (state = DEFAULT_USER, action) => {
    switch(action.type) {
        case USER.GET_USERDATA:
            return { ...state, loading: true, error: null }

        case USER.GET_USERDATA_SUCCESS:
            return { ...state, loading: false, error: null, user: action.user }
        
        case USER.GET_USERDATA_ERROR:
            return { ...state, loading: false, error: action.message }

        case USER.UPDATE_FIRSTNAME:
            return { ...state, user: { ...state.user, firstname: action.firstname }};
        case USER.UPDATE_LASTNAME:
            return { ...state, user: { ...state.user, lastname: action.lastname }};
        case USER.UPDATE_NOTIFICATION_DAILY:
            return { ...state, user: { ...state.user, notification_daily: action.notificationDaily }};
        case USER.UPDATE_NOTIFICATION_IMMEDIATELY:
            return { ...state, user: { ...state.user, notification_immediately: action.notificationImmediately }};
        
        case USER.UPDATE_USERSETTINGS:
            return { ...state, loading: true, error: null };
        case USER.UPDATE_USERSETTINGS_SUCCESS:
            return { ...state, loading: false, error: null };
        case USER.UPDATE_USERSETTINGS_ERROR:
            return { ...state, loading: false, error: action.error };

        default:
            return state;
    }
};

export default userReducer;