import { USER } from './types';
import { authHeader } from '../helpers/auth';

const API_URL = 'https://www.stumbls.de/api';

// ###################
// GET USER DATA
// ###################
export const fetchUserData = () => dispatch => {
    dispatch(getUserData());

    return fetch(`${API_URL}/user`, {
            method: 'GET',
            headers: authHeader()
        })
        .then(response => { 
            if(response.status !== 200) {
                throw new Error('Anfrage zu user Endpunkt fehlgeschlagen.')
            }
            return response.json();
        })
        .then(json => {
            if(!json.success) {
                throw new Error('Antwort von user Endpunkt fehlerhaft.')
            }
            dispatch(getUserDataSuccess(json));
        })
        .catch(error => dispatch(getUserDataError(error)));
} 
export const getUserData = () => {
    return { type: USER.GET_USERDATA };
}
export const getUserDataSuccess = userJson => {
    return { type: USER.GET_USERDATA_SUCCESS, user: userJson.data };
}
export const getUserDataError = error =>  {
    return { type: USER.GET_USERDATA_ERROR, message: error.message };
}

export const updateFirstname = firstname => {
    return { type: USER.UPDATE_FIRSTNAME, firstname};
}
export const updateLastname = lastname => {
    return { type: USER.UPDATE_LASTNAME, lastname};
}
export const updateNotificationDaily = notificationDaily => {
    return { type: USER.UPDATE_NOTIFICATION_DAILY, notificationDaily};
}
export const updateNotificationImmediately = notificationImmediately => {
    return { type: USER.UPDATE_NOTIFICATION_IMMEDIATELY, notificationImmediately};
}

export const updateUserSettings = (userId, firstname, lastname, notificationDaily, notificationImmediately) => dispatch => {
    dispatch(postUpdateUserSettings());

    var formData = new FormData();
    formData.append('firstname', firstname);
    formData.append('lastname', lastname);
    formData.append('notification_daily', notificationDaily);
    formData.append('notification_immediately', notificationImmediately);

    return fetch(`${API_URL}/user/${userId}`, {
            method: 'POST',
            headers: 
                authHeader(),
            body: formData
        })
        .then(response => { 
            if(response.status !== 200) {
                throw new Error('Fehlgeschlagen');
            }

            return response.json();
        })
        .then(json => {
            if(!json.success) {
                throw new Error('Fehlgeschlagen');
            }
            dispatch(updateUserSettingsSuccess(json));
        })
        .catch(error => dispatch(updateUserSettingsError(error)));
}
export const postUpdateUserSettings = () => {
    return { type: USER.UPDATE_USERSETTINGS };
}
export const updateUserSettingsSuccess = json => {
    return { type: USER.UPDATE_USERSETTINGS_SUCCESS, json };
}
export const updateUserSettingsError = error => {
    return { type: USER.UPDATE_USERSETTINGS_ERROR, error };
}