import { ADVERTISEMENT } from '../actions/types';

const DEFAULT = { };

const advertisementReducer  = (state = DEFAULT, action) => {
    switch(action.type) {
        case ADVERTISEMENT.GET_ADVERTISEMENT_FOR_TRIP_ID:
            return { ...state, loading: true, error: null };
        
        case ADVERTISEMENT.GET_ADVERTISEMENT_FOR_TRIP_ID_SUCCESS:
            let newAdvertisements = {...state.advertisements};
            newAdvertisements[action.uuid] = action.advertisements;
            return { ...state, loading: false, error: null, advertisements: newAdvertisements };

        case ADVERTISEMENT.GET_ADVERTISEMENT_FOR_TRIP_ID_ERROR:
            return { ...state, loading: false, error: action.message };

        case ADVERTISEMENT.DELETE_ADVERTISEMENT_FOR_TRIP:
            let newAdvertisements2 = { ...state.advertisements };
            try {
                delete newAdvertisements2[action.uuid];
                return { ...state, loading: false, error: null, advertisements: newAdvertisements2 };
            } catch(error) {
                return { ...state };
            }

        case ADVERTISEMENT.CLICK_ADVERTISEMENT_SUCCESS:
            return { ...state };

        default:
            return state;
    }
};

export default advertisementReducer;