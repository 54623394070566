import { AUTH } from '../actions/types';

const DEFAULT_AUTH = { loggedIn: false, registerLoading: false, loginLoading: false };

const authReducer  = (state = DEFAULT_AUTH, action) => {
    switch(action.type) {
        case AUTH.REGISTER:
            return { ...state, registerLoading: true, registerSuccessful: false, registerError: null };
        case AUTH.REGISTER_SUCCESS: 
            return { ...state, userId: action.payload.userId, registerLoading: false, registerSuccessful: true, registerError: null };
        case AUTH.REGISTER_ERROR:
            return { ...state, registerLoading: false, registerSuccessful: false, registerError: action.message };
        
        case AUTH.LOGIN:
            return { ...state, loginLoading: true, loginError: null}
        case AUTH.LOGIN_SUCCESS:
            return { ...state, loggedIn: true, loginLoading: false,  token: action.token, loginError: null};
        case AUTH.LOGIN_ERROR:
            return { ...state, loggedIn: false, loginLoading: false, loginError: action.message};

        case AUTH.LOGOUT:
            return { ...state, loggedIn: false}

        case AUTH.SET_TOKEN:
            return { ...state, loggedIn: true, token: action.token, tokenLoaded: true }
        case AUTH.SET_TOKEN_LOADING:
            return { ...state, loggedIn: false, token: null, tokenLoaded: true }
            
        case AUTH.FORGOT_PASSWORD:
            return { ...state, loading: true, success: false }
        case AUTH.FORGOT_PASSWORD_SUCCESS:
            return { ...state, loading: false, error: null, success: true }
        case AUTH.FORGOT_PASSWORD_ERROR:
            return { ...state, loading: false, error: action.message, success: false }

        case AUTH.SET_PASSWORD:
            return { ...state, loading: true }
        case AUTH.SET_PASSWORD_SUCCESS:
            return { ...state, loading: false, error: null }
        case AUTH.SET_PASSWORD_ERROR:
            return { ...state, loading: false, error: action.message }
        
        default:
            return state;
    }
};

export default authReducer;