import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { fetchUserData, updateFirstname, updateLastname, updateNotificationDaily, updateNotificationImmediately, updateUserSettings } from '../actions/user';
import { logoutUser } from '../actions/auth';

class UserSettings extends Component {
    componentDidMount() {
        this.props.fetchUserData();
    }

    handleInput = event => {
        if(event.Key === 'Enter') {
            this.saveUsersettings();
        }
    }

    saveUsersettings = () => {
        const { user } = this.props.user;

        const notification_daily = user.notification_daily ? "1" : "0";
        const notification_immediately = user.notification_immediately ? "1" : "0";

        this.props.updateUserSettings(user.id, user.firstname, user.lastname, notification_daily, notification_immediately);
    }

    updateFirstname = event => {
        this.props.updateFirstname(event.target.value);
    }

    updateLastname = event => {
        this.props.updateLastname(event.target.value);
    }

    updateNotificationDaily = event => {
        this.props.updateNotificationDaily(event.target.checked);
    }

    updateNotificationImmediately = event => {
        this.props.updateNotificationImmediately(event.target.checked);
    }

    logoutUser = () => {
        this.props.logoutUser();
    }

    render() {
        const { authentication, user } = this.props;
        const { loading } = user;

        if(!authentication.loggedIn) {
            return (<Redirect to="/" />)
        }

        if(!user.user) return (<div>Loading...</div>);
        const profile = user.user;

        return (
            <div>
                <h4>Profil</h4>
                <input 
                    style={{ marginTop: 10 }}
                    onKeyPress={this.handleInput} 
                    onChange={this.updateFirstname}
                    disabled={loading}
                    value={profile.firstname}
                    type="text" 
                    placeholder="Vorname" 
                    className="form-control"
                />

                <input 
                    style={{ marginTop: 10 }}
                    onKeyPress={this.handleInput} 
                    onChange={this.updateLastname} 
                    disabled={loading}
                    value={profile.lastname}
                    type="text" 
                    placeholder="Nachname" 
                    className="form-control"
                />

                <h4 style={{ marginTop: 20 }}>Benachrichtigungen</h4>

                <div className="mt10">
                    <input 
                        style={{ marginTop: 10 }}
                        disabled={loading}
                        onChange={this.updateNotificationDaily} 
                        checked={profile.notification_daily == "0" ? false : true}
                        className="form-check-input" 
                        type="checkbox" 
                        value="" 
                        id="notificationDaily" />
                    <label className="form-check-label" htmlFor="notificationDaily">
                        T&auml;gliche Benachrichtigung
                    </label>
                </div>

                <div className="mt10">
                    <input 
                        style={{ marginTop: 10 }}
                        disabled={loading}
                        onChange={this.updateNotificationImmediately} 
                        checked={profile.notification_immediately == "0" ? false : true}
                        className="form-check-input" 
                        type="checkbox" 
                        value="" 
                        id="notificationImmediately" />
                    <label className="form-check-label" htmlFor="notificationImmediately">
                        Sofortige Benachrichtigung
                    </label>
                </div>

                <button 
                    style={{ marginTop: 10 }}
                    onClick={this.saveUsersettings}
                    disabled={loading}
                    className="btn btn-secondary btn-block">
                    { loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: 10 }}></span> : null }
                    SPEICHERN
                </button>

                <hr />

                <button 
                    style={{ marginTop: 10 }}
                    onClick={this.logoutUser}
                    disabled={loading}
                    className="btn btn-primary btn-block">
                    { loading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: 10 }}></span> : null }
                    LOGOUT
                </button>
            </div>
        )
    }
}

export default connect(
    ({ authentication, user }) => ({ authentication, user }),
    { logoutUser, fetchUserData, updateFirstname, updateLastname, updateNotificationDaily, updateNotificationImmediately, updateUserSettings }
)(UserSettings);