import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

class LandingPage extends Component {
    render() {
        return (
            <div align="center">
                <Row>
                <Col className="align-middle">
                        <div className="bg bg1">
                            <p className="titleLandingPage backgroundHighlight">Reisen lebenswerter machen</p>
                        </div>
                        <p><Link to="/login" className="btn btn-secondary btn-200">LOGIN</Link></p>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <p><b>Soziale Kontakte</b></p>
                        <p>Du bist auch oft allein auf Reisen? Werde über Kollegen und Freunde in der gleichen Stadt benachrichtigt</p>
                    </Col>
                    <Col md={4}>
                        <p><b>Gemeinsame Erlebnisse</b></p>
                        <p>Du hast dir schon den ein oder anderen Abend im Hotel allein totgeschlagen? - Sei nichtmehr allein sondern triff dich mit anderen und unternimm etwas</p>
                    </Col>
                    <Col md={4}>
                        <p><b>Lokale Empfehlungen</b></p>
                        <p>Du wolltest etwas unternehmen und wusstest in einer fremden Stadt nicht wohin? Erhalte Empfehlungen für Städte, die du noch nicht bereist hast</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p><Link to="/register"  className="btn btn-secondary btn-200">JETZT REGISTRIEREN</Link></p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="bg bg2">
                            <p className="titleLandingPage backgroundHighlight">Du bist nicht allein</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <p><b>Steigende Reisebereitschaft</b></p>
                        <p>Die Anzahl der Geschäftsreisen steigt kontinuierlich, weil die Welt immer vernetzter wird. Damit erhöht sich die Wahrscheinlichkeit in einer anderen Stadt bekannte Gesichter zu treffen von denen du vielleicht garnicht wusstest, dass sie vorort sind. Wir benachrichtigen dich über diese Gesichter!</p>
                    </Col>
                    <Col md={4}>
                        <p><b>Fühlst du dich gelegentlich einsam?</b></p>
                        <p>Viele Reisende aüßern sich häufig sozial isoliert zu fühlen und beschreiben sich als einsam - beispielsweise allein im Hotel. Wir helfen Dir dich mit deinen Kontakten in der gleichen Stadt zu treffen und zeigen euch bei Bedarf auch, was ihr unternehmen könnt.</p>
                    </Col>
                    <Col md={4}>
                        <p><b>Übernachtung</b></p>
                        <p>Rund 40% der Geschäftsreisen sind mit mindestens einer Übernachtung. Den Feierabend kannst du entweder für dich nutzen oder stumbls dafür nutzen etwas mit deinen Freunden oder Kollegen zu unternehmen</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p><Link to="/register"  className="btn btn-secondary btn-200">MELD' DICH AN</Link></p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="bg bg3">
                            <p className="titleLandingPage backgroundHighlight">Wie es funktioniert ...</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <Row>
                          <p><b>1. </b>Registriere dich mit deiner beruflichen Mail. Erstmal brauchen wir nicht mehr von Dir! Du kannst gern später weitere Informationen in deinem Konto hinzufügen - musst es jedoch nicht.</p>
                        </Row>
                    </Col>
                    <Col md={4}>
                        <p><b>2. </b>Trage schnell und unkompliziert deine Reisen ein. Das Ausfüllen kostet dich in der Woche nicht mehr als 10 Sekunden. Wir nehmen deine Privatsphäre sehr ernst: Deswegen wollen wir nicht dauerhaft deine exakte Stadortfreigabe sondern beschränken uns lediglich auf die von dir eingetragene Stadt!</p>
                    </Col>
                    <Col md={4}>
                        <p><b>3. </b>Sieh sofort, wenn du gemeinsam mit jemandem aus deiner Firma unterwegs bist. Dein Reiseort wird nur an andere preisgegeben, wenn diese sich in der gleichen Stadt befinden. Nicht jeder kann einfach die Stadt sehen, in der du dich befindest.</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p><Link to="/register"  className="btn btn-secondary btn-200">REGISTIEREN</Link></p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="bg bg4">
                            <p className="titleLandingPage backgroundHighlight">Wir entwickeln stumbls für dich</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <p><b>Motivation</b></p>
                        <p>Wir sind selbst Vielreisende und kennen die Herausforderungen. Deshalb ist die Idee zu stumbls auch auf einer Reise entstanden.</p>
                    </Col>
                    <Col md={4}>
                        <p><b>Feedback</b></p>
                        <p>Durch Nutzerfeedback entwickeln wir stumbls so weiter, dass es den größten Nutzen generiert. Um stumbls zum perfekten Service für dich zu machen möchten wir aktiv dein Feedback bekommen. Schreibe uns also gern eine Mail!</p>
                    </Col>
                    <Col md={4}>
                        <p><b>Ausblick</b></p>
                        <p>Neben Kollegen kannst du in Zukunft auch Freunde hinzufügen. Du hast den Wunsch nach einem wichtigeren Feature? Dann teile deine Gedanken gern mit uns!</p>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default LandingPage;
