import { CITY } from '../actions/types';

const DEFAULT_CITY = { };

const cityReducer  = (state = DEFAULT_CITY, action) => {
    switch(action.type) {
        case CITY.GET_ALL_CITIES:
            return { ...state, loading: true, error: null };
        
        case CITY.GET_ALL_CITIES_ERROR:
            return { ...state, loading: false, error: action.message };

        case CITY.GET_ALL_CITIES_SUCCESS:
            return { ...state, loading: false, error: null, cities: action.cities };

        default:
            return state;
    }
};

export default cityReducer;