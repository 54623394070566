import store from '../store'
const JWTKey = 'MEETUPAPPJWT';

export function authHeader() {
    // return authorization header with jwt token
    // let token = localStorage.getItem(JWTKey);

    const state = store.getState()
    let token = state.authentication.token;
    if (token) {
        return { 'Authorization': 'Bearer ' + token };
    } else {
        return {};
    }
}