import { TRIP } from "./types";
import { authHeader } from "../helpers/auth";
import { getAdvertisementForTripId } from "./advertisement";

const API_URL = "https://www.stumbls.de/api";

// ###################
// GET TRIPS FOR USER AND ADD MISSING DAYS
// ###################
export const getTrips = () => dispatch => {
  dispatch(postGetTrips());

  return fetch(`${API_URL}/trip`, {
    method: "GET",
    headers: authHeader()
  })
    .then(response => {
      if (response.status !== 200) {
        throw new Error("Anfrage zu trip Endpunkt fehlgeschlagen.");
      }

      return response.json();
    })
    .then(json => {
      if (!json.success) {
        throw new Error("Antwort von trip Endpunkt fehlerhaft.");
      }

      dispatch(postGetTripsSuccess(json));
      dispatch(addTrips());
    })
    .catch(error => dispatch(postGetTripsError(error)));
};
export const addTrips = () => {
  return { type: TRIP.ADD_TRIPS };
};
export const postGetTrips = () => {
  return { type: TRIP.GET_TRIPS };
};
export const postGetTripsSuccess = tripsJson => {
  const trips = tripsJson.data;
  return { type: TRIP.GET_TRIPS_SUCCESS, trips };
};
export const postGetTripsError = error => {
  return { type: TRIP.GET_TRIPS_ERROR, message: error.message };
};

// ###################
// UPDATE CITY FOR DROPDOWNS
// ###################
export const updateSelectedCity = (uuid, value) => {
  return { type: TRIP.UPDATE_SELECTED_CITY, uuid, value };
};

// ###################
// SAVE ALL CHANGED TRIPS
// ###################
export const saveTrips = trips => dispatch => {
  // dispatch(postSaveTrips());

  for (var i = 0; i < trips.length; i++) {
    const trip = trips[i];
    var tripId = Number(trip.id);
    var cityId = Number(trip.city_id);
    var { date, changed, uuid } = trip;

    // If trip_id == 0 && city_id != 0 -> create
    if (!tripId && cityId != 0 && changed) {
      dispatch(createTrip(uuid, cityId, date));
    }
    // If trip_id != 0 && city_id != 0 -> update
    if (tripId && cityId != 0 && changed) {
      dispatch(updateTrip(uuid, tripId, cityId, date));
    }
    // If trip_id != 0 && city_id == 0 -> delete
    if (tripId && cityId == 0 && changed) {
      dispatch(deleteTrip(uuid, tripId));
    }
  }
};

export const saveTrip = (trip, cityId) => dispatch => {
  var tripId = Number(trip.id);
  var { date, uuid } = trip;

  dispatch(postSaveTrip(uuid));

  // If trip_id == 0 && city_id != 0 -> create
  if (!tripId && cityId != 0) {
    dispatch(createTrip(uuid, cityId, date));
  }
  // If trip_id != 0 && city_id != 0 -> update
  if (tripId && cityId != 0) {
    dispatch(updateTrip(uuid, tripId, cityId, date));
  }
  // If trip_id != 0 && city_id == 0 -> delete
  if (tripId && cityId == 0) {
    dispatch(deleteTrip(uuid, tripId));
  }
  if (!tripId && cityId == 0) {
    dispatch(deleteTripSuccess(uuid, "test"));
  }
};

export const postSaveTrip = uuid => {
  return { type: TRIP.SAVE_TRIP, uuid };
};

// ###################
// CREATE TRIP
// ###################
export const createTrip = (uuid, cityId, date) => dispatch => {
  dispatch(createTripLoading(uuid));

  var formData = new FormData();
  formData.append("timestamp", date);
  formData.append("city_id", cityId);

  fetch(`${API_URL}/trip`, {
    method: "POST",
    headers: authHeader(),
    body: formData
  })
    .then(response => {
      return response.json();
    })
    .then(json => {
      dispatch(createTripSuccess(uuid, json.data));
    })
    .catch(error => dispatch(createTripError(uuid, error)));
};
export const createTripLoading = uuid => {
  return { type: TRIP.CREATE_TRIP, uuid };
};
export const createTripSuccess = (uuid, trip) => {
  return { type: TRIP.CREATE_TRIP_SUCCESS, trip, uuid };
};
export const createTripError = (uuid, error) => {
  return { type: TRIP.CREATE_TRIP_ERROR, message: error.message, uuid };
};

// ###################
// UPDATE TRIP
// ###################
export const updateTrip = (uuid, tripId, cityId, date) => dispatch => {
  dispatch(updateTripLoading(uuid));

  var formData = new FormData();
  formData.append("timestamp", date);
  formData.append("city_id", cityId);

  fetch(`${API_URL}/trip/${tripId}`, {
    method: "POST",
    headers: authHeader(),
    body: formData
  })
    .then(response => {
      return response.json();
    })
    .then(json => {
      dispatch(updateTripSuccess(uuid, json.data));
    })
    .catch(error => {
      dispatch(updateTripError(uuid, error));
    });
};
export const updateTripLoading = uuid => {
  return { type: TRIP.UPDATE_TRIP, uuid };
};
export const updateTripSuccess = (uuid, trip) => {
  return { type: TRIP.UPDATE_TRIP_SUCCESS, trip, uuid };
};
export const updateTripError = (uuid, error) => {
  return { type: TRIP.UPDATE_TRIP_ERROR, message: error.message, uuid };
};

// ###################
// DELETE TRIP
// ###################
export const deleteTrip = (uuid, tripId) => dispatch => {
  dispatch(deleteTripLoading(uuid));

  fetch(`${API_URL}/trip/${tripId}`, {
    method: "DELETE",
    headers: authHeader()
  })
    .then(response => {
      return response.json();
    })
    .then(json => {
      dispatch(deleteTripSuccess(uuid, json.data));
    })
    .catch(error => dispatch(deleteTripError(uuid, error)));
};
export const deleteTripLoading = uuid => {
  return { type: TRIP.DELETE_TRIP, uuid };
};
export const deleteTripSuccess = (uuid, tripJson) => {
  return { type: TRIP.DELETE_TRIP_SUCCESS, trip: tripJson.data, uuid };
};
export const deleteTripError = (uuid, error) => {
  return { type: TRIP.DELETE_TRIP_ERROR, message: error.message, uuid };
};
