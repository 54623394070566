import React, { Component } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Button, Spinner, Row, Col } from 'react-bootstrap';
import { loginUser } from '../actions/auth';

class Login extends Component {
    state = { email: '', password: '', redirect: false };

    componentDidMount() {
        if(!this.props.location || !this.props.location.state) return;
        if(this.props.location.state.email) {
            this.setState({ email: this.props.location.state.email });
        }
    }

    updateForm = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    sendForm = () => {
        event.preventDefault();

        const { email, password } = this.state;
        if(email.length == 0 || password.length == 0) {
            return null;
        }

        this.props.loginUser(email, password);
    }

    handleKeyPress = event => {
        if(event.Key === 'Enter') {
            this.sendForm();
        } 
    }

    render() {
        const { email, password } = this.state;
        const { authentication } = this.props;
        const { loginLoading } = authentication;

        if(authentication.loggedIn) {
            return (<Redirect to="/" />)
        }

        return(
            <div align="center">
                <Row className="justify-content-center">
                    <Col className="text-center">
                        <p className="stumblsHeader">Login</p>
                    </Col>
                </Row>
                <Form className="text-center">
                    <Form.Row className="justify-content-center m5">
                        <Form.Control 
                            className="text-center input-max-400"
                            type="email"
                            name="email"
                            placeholder="E-Mail"
                            onChange={this.updateForm} 
                            onKeyPress={this.handleKeyPress}
                            disabled={loginLoading}
                            value={email}
                        />
                    </Form.Row>
                    <Form.Row className="justify-content-center m5">
                        <Form.Control 
                            className="text-center input-max-400"
                            name="password"
                            type="password" 
                            placeholder="Passwort"
                            onChange={this.updateForm} 
                            onKeyPress={this.handleKeyPress}
                            disabled={loginLoading}
                        />
                    </Form.Row>
                    <Form.Row className="justify-content-center m5">
                        <Button 
                            variant="secondary"
                            className="btn-200"
                            type="submit"
                            onClick={this.sendForm}
                            disabled={loginLoading || email.length == 0 || password.length == 0}
                        >
                            { loginLoading ? <Spinner as="span" animation="border" role="status" aria-hidden="true" size="sm" className="spinner-button" /> : null }
                            EINLOGGEN
                        </Button>
                    </Form.Row>
                </Form>

                <p className="registerError">
                    { authentication.loginError }
                </p>

                <p>
                    <Link to={{ pathname:"/register", state: { email: this.state.email } }}>Noch nicht registriert?</Link>
                </p>
                <p>
                    <Link to={{ pathname:"/forgotpassword", state: { email: this.state.email } }}>Passwort vergessen?</Link>
                </p>
                {/* <p>
                    <Link to={{ pathname:"/forgotpassword", state: { email: this.state.email } }}>Noch nicht registriert?</Link>
                </p>             */}
            </div>
        )
    }
}

export default connect(
    ({ authentication }) => ({ authentication }),
    { loginUser }
)(withRouter(Login));