import { combineReducers } from 'redux';
import authReducer from './auth';
import tripReducer from './trip';
import errorReducer from './error';
import cityReducer from './city';
import userReducer from './user';
import advertisementReducer from './advertisement';

export default combineReducers({ 
    authentication: authReducer,
    trips: tripReducer,
    cities: cityReducer,
    errors: errorReducer,
    user: userReducer,
    advertisements: advertisementReducer,
})