import { CITY } from './types';
import { authHeader } from '../helpers/auth';
import { getTrips } from './trip';

const API_URL = 'https://www.stumbls.de/api';

// ###################
// GET CITY LIST
// ###################
export const getAllCities = () => dispatch => {
    dispatch(postGetAllCities());

    return fetch(`${API_URL}/city`, {
            method: 'GET',
            headers: authHeader()
        })
        .then(response => { 
            if(response.status !== 200) {
                throw new Error('Anfrage zu city Endpunkt fehlgeschlagen.')
            }
            return response.json();
        })
        .then(json => {
            if(!json.success) {
                throw new Error('Antwort von city Endpunkt fehlerhaft.')
            }
            dispatch(postGetAllCitiesSuccess(json));
            dispatch(getTrips());
        })
        .catch(error => dispatch(postGetAllCitiesError(error)));
} 
export const postGetAllCities = () => {
    return { type: CITY.GET_ALL_CITIES };
}
export const postGetAllCitiesSuccess = citiesJson => {
    return { type: CITY.GET_ALL_CITIES_SUCCESS, cities: citiesJson.data };
}
export const postGetAllCitiesError = error =>  {
    return { type: CITY.GET_ALL_CITIES_ERROR, message: error.message };
}