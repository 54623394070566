import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

class Footer extends Component {
    render() {
        return (
            <div>
                {this.props.children}

                <hr />

                <p><Link to='/imprint'>Impressum</Link> - <Link to='/privacy'>Datenschutz</Link></p>
            </div>
        )
    }
}

export default connect(
    ({  }) => ({  }),
    {  }
)(Footer);