import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/de';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import Attendees from './Attendees';
import Advertisement from './Advertisement';
import { getTrips, saveTrip, updateSelectedCity } from '../actions/trip';
import { getAllCities } from '../actions/city';
import { getAdvertisementForTripId } from '../actions/advertisement'

class Trip extends Component {
    getCityOptions() {
        const { cities } = this.props;
        if(!cities.cities) return [];

        var dropdownValues = [
            { value: 0, label: 'Keine Reise' }
        ];
        var citiesValues = cities.cities.map(city => {
            return { value: Number(city.id), label: this.getCityNameFromId(city.id) };
        });

        for(var i = 0; i < citiesValues.length; i++) {
            dropdownValues = [ ...dropdownValues, citiesValues[i]];
        }
        return dropdownValues;
    }
    getCityNameFromId = city_id => {
        const { cities } = this.props;
        if(!cities.cities) return "Unbekannt";
        let city = cities.cities.find(element => element.id === String(city_id));
        if(city) return city.name;
        return "Keine Reise";
    }

    handleCityChange = (uuid, selectedOption) => {
        var cityId = selectedOption.value;
        let trip = this.props.trip;

        // Updates city in dropdown
        this.props.updateSelectedCity(uuid, cityId);

        // Saves the trip
        this.props.saveTrip(trip, cityId);

        // TODO: Reload Advertisement for all trips not only for one
        this.props.getAdvertisementForTripId(uuid, cityId);
    }

    render() {
        const { trip } = this.props;
        const { uuid, date, city_id } = trip;

        const styles = {
            control: (provided) => ({
              ...provided,
            //   borderRadius: 0,
            }),
            menu: (provided) => ({
                ...provided,
                borderRadius: 0,
                marginTop: '2px',
            }),
        }

        return (
            <div>
                <div className="row">
                    <div className="col-3 col-md-2 divTripdate">
                        <Moment element="p" className="tripday" unix local locale="de" format="dddd">{date}</Moment>
                        <Moment element="p" className="tripdate" unix local locale="de" format="DD.MM.YYYY">{date}</Moment>
                    </div>
                    <div className="col-1 col-md-1 noSpacing">
                        <div className="d-flex divTripConfirmationContainer">
                            <div className="divTripConfirmation">
                                { (trip.updated ? 
                                    <FontAwesomeIcon icon={faCheck} title="Erfolgreich" style={{ color: "#28a745" }} /> : 
                                    (trip.error ? 
                                        <FontAwesomeIcon icon={faTimes} title="Fehler" style={{ color: "red" }} /> : 
                                        null)
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-8 col-md-9">
                        <div className="divTripcity">
                            <Select
                                styles={styles}
                                isDisabled={trip.loading}
                                value={[{value: city_id, label: this.getCityNameFromId(city_id)}]}
                                onChange={e => this.handleCityChange(uuid, e)}
                                options={this.getCityOptions()}
                            />
                        </div>
                        { trip.error ? <p style={{ color: "red" }}>{trip.error}</p> : null }
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    ({ cities, trips }) => ({ cities, trips }),
    { getAllCities, getTrips, saveTrip, updateSelectedCity, getAdvertisementForTripId }
)(Trip);



/*
// TODO: Remove clutter
        const styles = {
            control: (provided, state) => ({
              ...provided,
              borderRadius: 0,
            }),
            menu: (provided, state) => ({
                ...provided,
                borderRadius: 0,
                marginTop: '2px',
            }),
        }
        const stylesChanged = {
            control: (provided, state) => ({
              ...provided,
              borderRadius: 0,
            }),
            menu: (provided, state) => ({
                ...provided,
                borderRadius: 0,
                marginTop: '2px',
            }),
        }
        const stylesUpdated = {
            control: (provided, state) => ({
              ...provided,
              borderRadius: 0,
              borderColor: '#00AB66',
            }),
            menu: (provided, state) => ({
                ...provided,
                borderRadius: 0,
                marginTop: '2px',
            }),
        }
        const stylesError = {
            control: (provided, state) => ({
              ...provided,
              borderRadius: 0,
              borderColor: '#7f0000',
            }),
            menu: (provided, state) => ({
                ...provided,
                borderRadius: 0,
                marginTop: '2px',
            }),
        }




        */