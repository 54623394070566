import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history'
import Footer from './components/Footer';
import Header from './components/Header';
import App from './components/App';
import Register from './components/Register';
import SetPassword from './components/SetPassword';
import ForgotPassword from './components/ForgotPassword';
import UserSettings from './components/UserSettings';
import Login from './components/Login';
import Imprint from './components/Imprint';
import Privacy from './components/Privacy';
import store from './store';
import './index.css';

ReactDOM.render(
    <Provider store={store}>
        <Router history={createBrowserHistory()}>
            <Switch>
                <Route exact path='/' render={() => <Header><Footer><App /></Footer></Header>} />
                <Route path='/register' render={() => <Header><Footer><Register /></Footer></Header>} />
                <Route path='/login' render={() => <Header><Footer><Login /></Footer></Header>} />
                <Route path='/user' render={() => <Header><Footer><UserSettings /></Footer></Header>} />
                <Route path='/forgotpassword' render={() => <Header><Footer><ForgotPassword /></Footer></Header>} />
                <Route path='/resetpassword/:token' render={() => <Header><Footer><SetPassword /></Footer></Header>} />
                <Route path='/imprint' render={() => <Header><Footer><Imprint /></Footer></Header>} />
                <Route path='/privacy' render={() => <Header><Footer><Privacy /></Footer></Header>} />
            </Switch>
        </Router>
    </Provider>,
    document.getElementById('root')
);
